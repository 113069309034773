<template lang="pug">
  datepicker(:value="item.due_date" @input="update" hideDetails)
    template(v-slot:activator="datepicker")
      span(@click="datepicker.show")
        template(v-if="item.due_date") {{ item.due_date | formattedDate }}
        template(v-else)
          ico-calendar(color="#758289").pointer
    template(v-slot:default="props")
      div
        v-date-picker(
          no-title
          :value="datepickerValue"
          @input="update($event)"
          @change="props.process"
        )
        .datepicker-bottom(@click="props.process")
          v-btn(block color="primary" @click="update(null)") Remove
</template>

<script>
import Dayjs from "dayjs"
import apiTaskApllications from '@/services/api/api-taskApllications'
import taskApplicationsService from '../core/taskApplications-service'
import errorsMixin from '@/mixins/errors.mixin'
import featureFlagsMixin from '@/mixins/featureFlags.mixin'
import { convertToDefaultBackendFormat } from '@/util'

export default {
  name: 'DueDate',

  mixins: [errorsMixin, featureFlagsMixin],

  props: {
    item: Object,
    customUpdate: Boolean
  },

  computed: {
    datepickerValue() {
      if (!this.item.due_date) return null
      return convertToDefaultBackendFormat(this.item.due_date)
    }
  },

  methods: {
    async update(date) {
      if (this.loading) return

      try {
        let _date = this.getFormattedDate(date)
        if (this.customUpdate) return this.$emit('change', _date)
        this.loading = true
        await new taskApplicationsService(apiTaskApllications).updateDueDate(this.item.id, _date)
        this.item.due_date = date
        this.$notify({type: 'success', text: 'Updated'})
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    getFormattedDate(date) {
      if (!date) return null
      return Dayjs(new Date(date)).toISOString()
    }
  },

  components: {
    icoCalendar: () => import('@/assets/img/ui/crm/IcoCalendar.vue'),
    Datepicker: () => import('@/app/admin/components/CrmDatepicker.vue'),
  }
}
</script>
